import React, { useEffect, useRef } from "react";
const TrustPilotWidget = ({ reviewTag }) => {
    const ref = useRef(null);
    useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, [reviewTag]);
    return (
        <div>
            <script
                type="text/javascript"
                src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
                >
            </script>
            <div
                ref={ref}
                className="trustpilot-widget"
                data-locale="en-GB"
                data-template-id="54ad5defc6454f065c28af8b"
                data-businessunit-id="652f7b73f94ff92f25fbe727"
                data-style-height="250px"
                data-style-width="100%"
                data-theme="white"
                data-stars="5"
                data-tags={reviewTag}
            />
        </div>
    );
};
export default TrustPilotWidget;