import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/global/Layout";
import Banner from "../components/global/Banner";
import BarSpecialOffer from "../components/global/BarSpecialOffer";
import OurServices from "../components/global/OurServices";
import OurFeatures from "../components/global/OurFeatures";
import RecentProjects from "../components/global/RecentProjects";
import Testimonials from "../components/global/Testimonials";
import GetInTouch from "../components/global/forms/GetInTouch";
import { parseSeoFromQuery } from "../components/global/SEO";
import TrustPilotWidget from "../components/global/product/TrustPilotWidget";
import PlaceholderContentBlocks from "../components/global/product/PlaceholderContentBlocks";

export default function Home(props) {
  const data = props?.data?.markdownRemark?.frontmatter;

  const specialOffer = data?.barSpecialOffer;
  const title = data?.barSpecialOffer?.title;
  const titleBold = data?.barSpecialOffer?.titleBold;
  const buttonText = data?.barSpecialOffer?.buttonText;
  const buttonLink = data?.barSpecialOffer?.buttonLink;

  const hasSpecialOffer =
    specialOffer && title && titleBold && buttonText && buttonLink;
  const hasTrustPilotWidget = data?.trustPilotWidget?.toLowerCase() === 'active';

  const specialOfferComponent = hasSpecialOffer ? (
    <BarSpecialOffer {...data?.barSpecialOffer} />
  ) : (
    <></>
  );

  const trustPilotWidget = hasTrustPilotWidget ? (
      <TrustPilotWidget reviewTag={data?.reviewTag} />
  ) : (
      <></>
  );

  return (
    <Layout {...parseSeoFromQuery(props?.data)}>
      <Banner {...data?.banner} />
      {specialOfferComponent}
      <OurServices {...data?.ourServices} hasSpecialOffer={hasSpecialOffer} />
      <PlaceholderContentBlocks {...data?.placeholderContentBlocks} isPreview={false}/>
      <OurFeatures {...data?.ourFeatures} />
      <RecentProjects {...data?.recentProjects} />
      <Testimonials {...data?.testimonials} />
      {trustPilotWidget}
      <GetInTouch {...data?.getInTouch} />
    </Layout>
  );
}

export const query = graphql`
  query ($page: String, $locale: String) {
    markdownRemark(
      frontmatter: { page: { eq: $page }, locale: { eq: $locale } }
    ) {
      frontmatter {
        ...seo
        ...getInTouch
        banner {
          subtitle
          title1
          title2
        }
        barSpecialOffer {
          titleBold
          title
          buttonText
          buttonLink
        }
        trustPilotWidget
        reviewTag
        placeholderContentBlocks {
          firstBlock {
            isVisible
            type
            text
            photo
            video 
            buttonText
            buttonNavigation
            linkText
            linkNavigation
            contentWidth
            contentHeight
            keywords
            siteUrl
          }
          secondBlock {
            isVisible
            type
            text
            photo 
            video
            buttonText
            buttonNavigation
            linkText
            linkNavigation
            contentWidth
            contentHeight
            keywords
            siteUrl
          }
          thirdBlock {
            isVisible
            type
            text
            photo 
            video
            buttonText
            buttonNavigation
            linkText
            linkNavigation
            contentWidth
            contentHeight
            keywords
            siteUrl
          }
        }  
        ourFeatures {
          subtitle
          title
          titleGreen
          description
          link {
            text
            route
          }
        }
        ourServices {
          title
          titleGreen
          description
          buttonText
          buttonLink
        }
        testimonials {
          title
          quotes {
            quote
            role
            industry
            location
          }
        }
        title
        recentProjects {
          title
          projects {
            route
            title
            subTitle
          }
        }
      }
    }
  }
`;
